import React, { useState, useEffect, useRef } from 'react';
import { firestore } from '../services/firebase';
import { collection, addDoc, query, orderBy, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { FaComments, FaTimes } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';
import '../styles/Chat.css';

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { currentUser } = useAuth();
    const [displayName, setDisplayName] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                const userDoc = doc(firestore, 'users', currentUser.uid);
                const userSnapshot = await getDoc(userDoc);
                if (userSnapshot.exists()) {
                    setDisplayName(userSnapshot.data().displayName || currentUser.email);
                }
            }
        };

        fetchUserData();
    }, [currentUser]);

    useEffect(() => {
        const messagesCollection = collection(firestore, 'messages');
        const q = query(messagesCollection, orderBy('timestamp', 'asc'));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messagesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(messagesData);
        });

        return () => unsubscribe();
    }, []);

    const scrollToBottom = () => {
        const messagesList = document.querySelector('.messages-list');
        if (messagesList) {
            messagesList.scrollTop = messagesList.scrollHeight;
        }
    };

    useEffect(() => {
        if (isOpen) {
            setTimeout(scrollToBottom, 100);
        }
    }, [isOpen]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (newMessage.trim() === '') return;

        try {
            await addDoc(collection(firestore, 'messages'), {
                text: newMessage,
                userId: currentUser.uid,
                displayName: currentUser.displayName || currentUser.email,
                timestamp: new Date()
            });
            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="chat-wrapper">
            <button className="toggle-button" onClick={toggleChat}>
                {isOpen ? <FaTimes /> : <FaComments />}
            </button>
            {isOpen && (
                <div className="chat-bubble">
                    <div className="chat-container">
                        <div className="messages-list">
                            {messages.map(message => (
                                <div 
                                    key={message.id} 
                                    className={`message-item ${message.userId === currentUser.uid ? 'my-message' : ''}`}
                                >
                                    <span className="message-user">{message.userId === currentUser.uid ? displayName : message.displayName}</span>
                                    <p className="message-text">{message.text}</p>
                                    <span className="message-timestamp">
                                        {formatDistanceToNow(new Date(message.timestamp.seconds * 1000), { addSuffix: true })}
                                    </span>
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                        <form onSubmit={handleSendMessage} className="message-form">
                            <input
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Type your message..."
                                className="message-input"
                            />
                            <button type="submit" className="send-button">Send</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chat;
