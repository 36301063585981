import React from 'react';
import '../../styles/Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <h1>👑</h1>
     
      <p>Qui va remporter Miss France 2025 cette année ? <br /> Place ton pari et assure-toi de rester éveillé jusqu'au bout pour voir les résultats ! <br /> 🌟💃</p>
     
    </div>
  );
};

export default Home;